// extracted by mini-css-extract-plugin
export var alignLeft = "y_qh d_fp d_bG d_dv";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignRight = "y_qj d_fr d_bH d_dx";
export var alignColumnLeft = "y_rK d_fs d_bN d_dv";
export var alignColumnCenter = "y_rL d_ft d_bP d_dw";
export var alignColumnRight = "y_rM d_fv d_bQ d_dx";
export var milestonesContainer = "y_sW d_dW";
export var milestonesContainerFull = "y_sX d_dT";
export var milestonesSubtitle = "y_mf d_mf d_cs";
export var milestonesComponentWrapper = "y_mb d_mb d_cv";
export var compContentWrapper = "y_rQ d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "y_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "y_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "y_sY d_H";
export var milestonesBlockWrapper = "y_sZ";
export var milestonesTextBlockWrapper = "y_s0";
export var milestonesComponentHeader = "y_md d_md d_w d_cr";
export var milestonesComponentParagraph = "y_mj d_mj d_w";
export var btnWrapper = "y_d2 d_bz d_ck";
export var btnWrapperCards = "y_rS d_bz d_cd";
export var imageWrapper = "y_rw d_cs d_bz";
export var Title1Small = "y_sG D_sG D_tq D_tr";
export var Title1Normal = "y_sH D_sH D_tq D_ts";
export var Title1Large = "y_sJ D_sJ D_tq D_tt";
export var Title2Small = "y_s1 D_s1 D_tq D_tv";
export var Title2Normal = "y_s2 D_s2 D_tq D_tw";
export var Title2Large = "y_s3 D_s3 D_tq D_tx";
export var SubtitleSmall = "y_qd D_qd D_tq D_tC";
export var SubtitleNormal = "y_qf D_qf D_tq D_tD";
export var SubtitleLarge = "y_qg D_qg D_tq D_tF";
export var Subtitle2Small = "y_s4 D_s4 D_tq D_tG";
export var Subtitle2Normal = "y_s5 D_s5 D_tq D_tH";
export var Subtitle2Large = "y_s6 D_s6 D_tq D_tJ";
export var BodySmall = "y_sK D_sK D_tq D_tK";
export var BodyNormal = "y_sL D_sL D_tq D_tL";
export var BodyLarge = "y_sM D_sM D_tq D_tM";
export var exceptionLineHeight = "y_s7 D_tT";