// extracted by mini-css-extract-plugin
export var lbContainerOuter = "H_wc";
export var lbContainerInner = "H_wd";
export var movingForwards = "H_wf";
export var movingForwardsOther = "H_wg";
export var movingBackwards = "H_wh";
export var movingBackwardsOther = "H_wj";
export var lbImage = "H_wk";
export var lbOtherImage = "H_wl";
export var prevButton = "H_wm";
export var nextButton = "H_wn";
export var closing = "H_wp";
export var appear = "H_wq";