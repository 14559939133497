// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_st d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_sv d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_sw d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_sx d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_sy d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_sz d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_sB d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_sC D_sC";
export var heroExceptionNormal = "w_sD D_sD";
export var heroExceptionLarge = "w_sF D_sF";
export var Title1Small = "w_sG D_sG D_tq D_tr";
export var Title1Normal = "w_sH D_sH D_tq D_ts";
export var Title1Large = "w_sJ D_sJ D_tq D_tt";
export var BodySmall = "w_sK D_sK D_tq D_tK";
export var BodyNormal = "w_sL D_sL D_tq D_tL";
export var BodyLarge = "w_sM D_sM D_tq D_tM";