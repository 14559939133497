// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "r_q8 d_bD";
export var datasheetRowWrapper = "r_lt d_lt d_bK";
export var datasheetLeftWrapper = "r_q9 d_bz d_bP";
export var datasheetWrapperFull = "r_rb d_cD";
export var datasheetWrapperFullLeft = "r_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "r_mv d_lv";
export var contentWrapperCenter = "r_rc d_lz";
export var datasheetLeftWrapperCenter = "r_rd d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "r_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "r_rf d_lw";
export var datasheetFooter = "r_ly d_ly";
export var alignLeft = "r_qh d_dv";
export var alignCenter = "r_bP d_dw";
export var alignRight = "r_qj d_dx";
export var datasheetInnerWrapperNewLeft = "r_rg d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "r_rh d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "r_rj d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "r_rk d_bK";
export var datasheetSubtitle = "r_rl d_cx";
export var tableRow = "r_rm";
export var cell = "r_rn";
export var tableRowWrapper = "r_rp d_w d_dv";
export var tableHeadingCell = "r_rq";
export var tableHeading = "r_rr";
export var tableRowStriped = "r_rs";
export var tableRowHeading = "r_rt";
export var dataTable = "r_rv d_w";
export var imageWrapper = "r_rw d_fg d_Z";
export var imageWrapperFull = "r_rx d_H d_w d_bf d_Z";
export var imageWrapperIcon = "r_ry";
export var titleMargin = "r_rz d_cs";
export var datasheetInnerInnerWrapper = "r_rB d_w";
export var hasLabels = "r_rC";
export var label = "r_rD";
export var SmallSmall = "r_rF D_rF D_tq D_tN";
export var SmallNormal = "r_rG D_rG D_tq D_tP";
export var SmallLarge = "r_rH D_rH D_tq D_tL";