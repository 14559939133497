// extracted by mini-css-extract-plugin
export var alignLeft = "x_qh d_bG";
export var alignCenter = "x_bP d_bD";
export var alignRight = "x_qj d_bH";
export var textAlignLeft = "x_sN";
export var textAlignCenter = "x_sP";
export var textAlignRight = "x_sQ";
export var hoursInnerWrapperAlt = "x_sR d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "x_sS d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "x_sT d_dw";
export var titleMargin = "x_rz d_cw";
export var hoursInnerInnerWrapper = "x_sV d_cz";